/** @format */
import React from 'react'

import {HeadTextIntro} from '../components'

export default () => (
  <>
    <HeadTextIntro english>Our Work!</HeadTextIntro>
  </>
)
